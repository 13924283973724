import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Read.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/features/userSlice";
import { onValue, ref, remove } from "firebase/database";
import { db } from "../../FirebaseConfig";

export default function Read() {
  const user = useSelector(selectUser);
  const { pathname } = useLocation();
  const [color, setColor] = useState(null);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [blogData, setBlogData] = useState({});
  const [styles, setStyles] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    const randomColors = ["blue", "rgb(2, 104, 2)", "red", "black", "purple"];
    setColor(randomColors[Math.floor(Math.random() * randomColors.length)]);
  }, []);

  useEffect(() => {
    const blogKey = pathname.split("/").pop();
    const blogRef = ref(db, "blogsData");

    const unsubscribe = onValue(
      blogRef,
      (snapshot) => {
        const data = snapshot.val()?.[blogKey];
        if (data) setBlogData({ ...data, blogKey });
        setIsLoading(false);
      },
      () => setIsLoading(false)
    );

    return unsubscribe;
  }, [pathname, isDeleteModal, user]);

  useEffect(() => {
    if (!blogData?.blogContent) return;

    const newStyles = {};

    Object.values(blogData.blogContent)[0]?.forEach((item) => {
      const { inlineStyleRanges, type, key } = item;

      if (inlineStyleRanges) {
        inlineStyleRanges.forEach(({ style }) => {
          newStyles[key] = {
            ...newStyles[key],
            fontWeight: style === "BOLD" ? "bold" : undefined,
            textDecoration: style === "UNDERLINE" ? "underline" : undefined,
            fontStyle: style === "ITALIC" ? "italic" : undefined,
          };
        });
      }

      const blockStyles = {
        "header-one": { fontSize: "32px" },
        "header-two": { fontSize: "24px" },
        "unordered-list-item": { listStyleType: "disc" },
        "ordered-list-item": { listStyleType: "decimal" },
      };

      newStyles[key] = { ...newStyles[key], ...blockStyles[type] };
    });

    setStyles(newStyles);
  }, [blogData?.blogContent]);

  const deleteBlogById = (id) => {
    remove(ref(db, `blogsData/${id}`))
      .then(() => {
        console.log(`Blog with ID ${id} deleted successfully.`);
        window.location.href = "/";
      })
      .catch((error) => console.error("Error deleting blog:", error));
  };

  return (
    <div className="Read">
      <div className="hero-section">
        <div
          className="gradient-overlay"
          style={{
            background: `linear-gradient(to bottom, ${color}, transparent)`,
          }}
        />
        <div className="hero-content">
          <h1>{blogData?.title}:</h1>
          <h2>{blogData?.subTitle}</h2>
          <div className="author">
            <div
              className="author-profile"
              style={{ backgroundImage: `url(${blogData?.authorPhoto})` }}
            />
            <p className="author-name">{blogData?.authorName}</p>
            <p className="article-date">{blogData?.date}</p>
          </div>
          <div className="hero-image-container">
            <div
              className="hero-image"
              style={{
                backgroundImage: `url(${
                  blogData?.heroImage || blogData?.thumbnail
                })`,
              }}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="p-content">
          {Object.values(blogData?.blogContent || {})[0]?.map((item) => (
            <p key={item.key} style={styles[item.key]}>
              {item.text}
            </p>
          ))}
        </div>
      )}

      {user &&
        process.env.REACT_APP_AUTHORIZED_EMAILS.split(",").includes(
          user?.email
        ) && (
          <div className="action-btns">
            <Link
              to={`/edit/${blogData?.title}/${blogData?.blogKey}`}
              className="action-btn edit-btn"
              state={blogData}
            >
              <EditIcon className="icon" />
            </Link>
            <div
              onClick={() => setIsDeleteModal(!isDeleteModal)}
              className="action-btn delete"
            >
              <DeleteIcon className="icon" />
            </div>
          </div>
        )}

      {isDeleteModal && (
        <div className="delete-modal">
          <h3>Are you sure you want to permanently delete this?</h3>
          <div
            className="delete-btn"
            onClick={() => deleteBlogById(blogData?.blogKey)}
          >
            Delete
          </div>
          <div className="cancel-btn" onClick={() => setIsDeleteModal(false)}>
            Cancel
          </div>
        </div>
      )}
    </div>
  );
}
