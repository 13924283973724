import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getDatabase, ref, onValue, update } from "firebase/database";
import { storage } from "../../FirebaseConfig";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

const categories = ["management", "engineering", "sales", "support"];

const Edit = () => {
  const { category, id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    role: "",
    description: "",
    imageUrl: "",
    newImage: null,
    newCategory: category,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const db = getDatabase();
    const teamRef = ref(db, `team/${category}/${id}`);
    onValue(teamRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setFormData((prev) => ({ ...prev, ...data }));
      }
    });
  }, [category, id]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "newImage" && files[0]) {
      setFormData((prev) => ({ ...prev, newImage: files[0] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleImageUpload = async () => {
    if (!formData.newImage) return formData.imageUrl;

    const imageRef = storageRef(
      storage,
      `team/${formData.newCategory}/${formData.newImage.name}`
    );
    await uploadBytes(imageRef, formData.newImage);
    return getDownloadURL(imageRef);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const imageUrl = await handleImageUpload();
      const db = getDatabase();
      const oldPath = `team/${category}/${id}`;
      const newPath = `team/${formData.newCategory}/${id}`;

      const updates = {
        [newPath]: { ...formData, imageUrl },
      };

      if (oldPath !== newPath) updates[oldPath] = null; // Delete old entry if category changed

      await update(ref(db), updates);
      navigate("/dashboard/team");
    } catch (error) {
      console.error("Error updating data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="edit-container">
      <h2>Edit Team Member</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          value={formData.name}
          placeholder="Name"
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="role"
          value={formData.role}
          placeholder="Role"
          onChange={handleChange}
          required
        />
        <textarea
          name="description"
          value={formData.description}
          placeholder="Description"
          onChange={handleChange}
          required
        />
        <select
          name="newCategory"
          value={formData.newCategory}
          onChange={handleChange}
          required
        >
          {categories.map((cat) => (
            <option key={cat} value={cat}>
              {cat.charAt(0).toUpperCase() + cat.slice(1)}
            </option>
          ))}
        </select>
        <input
          type="file"
          name="newImage"
          accept="image/*"
          onChange={handleChange}
        />
        <button type="submit" disabled={loading}>
          {loading ? "Updating..." : "Update Member"}
        </button>
      </form>
    </div>
  );
};

export default Edit;
