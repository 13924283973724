import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Read from "./pages/read/Read";
import Login from "./pages/registrations/Login";
import Signin from "./pages/registrations/Signin";
import Create from "./pages/create/Create";
import Edit from "./pages/edit/Edit";
import Featured from "./pages/featured/Featured";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <div className="content">
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/featured" element={<Featured />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/create" element={<Create />} />
            <Route path="/edit/:title/:id" element={<Edit />} />
            <Route path="/blog/:category/:title/:id" element={<Read />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
